.experience-container {
  padding: 20px;
  font-family: var(--font-family);
  background-color: var(--primary-bg);
}

.exp_card {
  position: relative; 
  display: flex;
  align-items: flex-start;
  border: 3px solid var(--highlight-color);
  border-radius: 16px;
  padding: 20px;
  margin: 20px;
}

.view-button-container {
  position: absolute;
  top: 20px;
  right: 20px;
}

.button {
  padding: 10px 20px;
  font-size: 21px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  background-color: var(--highlight-color);
  color: var(--secondary-bg);
}

.button:hover {
  background-color: var(--accent-color);
}

.exp-image {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.exp-details {
  display: flex;
  flex-direction: column;
  color: var(--text-color);
}

.exp-details h2 {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 36px;
  color: var(--accent-color);
}

.exp-position {
  font-size: 30px;
  margin-top: 0;
  padding-top: 0;
  font-weight: 300;
  margin-bottom: 0;
  padding-bottom: 0;
  color: var(--highlight-color);
}

.exp-duration {
  font-size: 26px;
  margin-bottom: 20px;
  color: var(--accent-color);
}

.exp-summary {
  font-size: 30px;
  line-height: 1.6;
}

.exp-summary ul {
  margin-left: 20px;
  list-style: disc;
}

.exp-summary li {
  margin-bottom: 10px;
}

ul li::marker {
  color: var(--highlight-color);
}

@media (max-width: 768px) {
  .exp_card {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    padding: 20px 10px;
  }

  .exp-image {
    margin-bottom: 20px; /* Add space below image */
    margin-right: 0; /* Remove right margin */
  }

  .exp-details {
    align-items: center; /* Center text in smaller devices */
    text-align: center; /* Center-align all text inside exp-details */
  }

  .exp-details h2 {
    font-size: 20px; /* Smaller font size */
  }

  .exp-position {
    font-size: 20px; 
  }

  .exp-duration {
    font-size: 20px;
    margin-bottom: 20px; 
  }

  .view-button-container {
    position: static; /* Remove absolute positioning */
    align-self: center; /* Center the button within the flex container */
    margin-top: 10px; /* Add space above the button */
    margin-bottom: 20px; /* Add space below the button */
  }

  .button {
    font-size: 15px;
  }

  .exp-summary {
    font-size: 22px;
  }
}
