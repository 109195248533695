.typing-animation-container {
  text-align: center;
  padding: 2rem;
  font-family: 'VT323', monospace;
  background-color: var(--primary-bg);
}

.typing-animation {
  font-size: 70px;
  color: var(--accent-color);
}

.arrow {
  font-size: 3rem;
  margin-top: 1rem;
  display: inline-block; /* Needed for transform to work */
  transform: rotate(90deg); /* Rotate the arrow 90 degrees */
  animation: bounce 2s infinite;
  font-weight: bold;
  color: var(--accent-color);
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) rotate(90deg); /* Ensure rotation stays during bounce */
  }
  40% {
    transform: translateY(-10px) rotate(90deg); /* Ensure rotation stays during bounce */
  }
  60% {
    transform: translateY(-5px) rotate(90deg); /* Ensure rotation stays during bounce */
  }
}

.image-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.center-image {
  max-width: 100%;
  height: auto;
}

/* Media query for smaller devices */
@media (max-width: 768px) {
  .typing-animation {
    font-size: 50px; /* Reduce font size for smaller screens */
    padding: 0 1rem; /* Add padding for better text fit */
  }

  .arrow {
    font-size: 2rem; /* Reduce arrow size */
  }

  .typing-animation-container {
    padding: 1rem; /* Reduce padding */
  }

  .center-image {
    width: 60%; /* Limit image width on smaller devices */
  }
}

@media (max-width: 480px) {
  .typing-animation {
    font-size: 18px; /* Further reduce font size for extra small screens */
    padding: 0px; /* Add more padding reduction */
  }

  .arrow {
    font-size: 1.5rem; /* Further reduce arrow size */
  }

  .center-image {
    width: 50%; /* Further limit image width on very small devices */
  }
}
