  .navbar {
    color: #fff;
    background-color: var(--primary-bg) !important;
    font-family: var(--font-family);
    font-size: 24px;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .navbar-brand {
    font-size: 29px;
    color: var(--highlight-color) !important;
    font-style: italic;
    margin-right: auto;
  }
  
  .nav-link {
    color: var(--highlight-color) !important;
    font-size: 29px;
  }
  
  .navbar-collapse {
    justify-content: flex-end;
  }
  
  .navbar-toggler {
    border: none;
    font-size: 1.5rem;
    color: var(--highlight-color);
  }
  
  .navbar-toggler-icon {
    background-image: none;
    width: 30px;
    height: 3px;
    background-color: var(--highlight-color);
    display: block;
    position: relative;
  }
  
  .navbar-toggler-icon::before,
  .navbar-toggler-icon::after {
    content: '';
    width: 30px;
    height: 3px;
    background-color: var(--highlight-color);
    position: absolute;
    left: 0;
    transition: all 0.2s;
  }
  
  .navbar-toggler-icon::before {
    top: -8px;
  }
  
  .navbar-toggler-icon::after {
    top: 8px;
  }
  
  .navbar-toggler.collapsed .navbar-toggler-icon::before {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .navbar-toggler.collapsed .navbar-toggler-icon::after {
    transform: rotate(-45deg) translate(5px, -5px);
  }
  
  @media (max-width: 768px) {
    .navbar-nav {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      background-color: var(--primary-bg);
    }
  
    .navbar-nav .nav-item {
      width: 100%;
      text-align: left;
      padding: 0rem 1rem;
    }
  
    .navbar-toggler {
      position: absolute;
      left: 0;
      margin: 0.5rem;
    }
  
    .navbar-brand {
      margin-left: auto;
      padding-right: 1rem;
    }
  
    .navbar-nav {
      display: none;
      position: absolute;
      top: 50px;
      left: 0;
      background-color: var(--primary-bg);
      width: 45%;
    }
  
    .navbar-collapse.show .navbar-nav {
      display: flex;
    }

    .nav-link{
      font-size: 20px;
    }
  }
  
